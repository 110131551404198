import React from "react";
import { UserTour } from "../../components/UserTour";
import { PageLayout } from "../../components/PageLayout";
import { userTourPageHeroImage } from "../../images";

const UserTourPage: React.FC = () => {
  return (
    <PageLayout
      heroBackgroundImage={`url(${userTourPageHeroImage})`}
      heroOverlayLeadElement="The User Experience"
      heroOverlaySecondaryElement="A guided tour from the user's perspective"
    >
      <UserTour />
    </PageLayout>
  );
};

export default UserTourPage;
