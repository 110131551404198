import * as React from "react";
import {
  bwbHomepageImage,
  wineImage,
  screenshotDfuAfterImage,
  screenshotDfuBeforeImage,
  screenshotMenuAfterImage,
  screenshotMenuBeforeImage,
  screenshotReportAfterImage,
  screenshotReportBeforeImage,
  screenshotReportListAfterImage,
  screenshotReportListBeforeImage,
  screenshotScreenAfterImage,
  screenshotScreenBeforeImage,
} from "../images";
import { BeforeAndAfterTwinOak } from "./BeforeAndAfterTwinOak";
import { ExpandableImage } from "./ExpandableImage";
import { PageHeader } from "./PageHeader";
import { Paragraph } from "./Paragraph";
import { Section } from "./Section";
import { Heading2 } from "./Heading2";
import { Heading3 } from "./Heading3";
import "./TwinOak.scss";

export const UserTour: React.FC = () => {
  return (
    <>
      <PageHeader primary="A Guided Tour" secondary="for users" />
      <Section>
        <Heading2>Using Your New Solution</Heading2>
        <div>
          <ExpandableImage
            src={bwbHomepageImage}
            className="tos_img_spa_diagram"
            thumbnailCaption="An example of the new user experience"
            expandedTitle="An example of the new user experience"
            expandedCaption="The Bob's Wine Bar demo site is an example of what your new user experience might look like."
          />
          <Paragraph>
            The biggest change for users of the translated system is that now
            the entire user interface runs in a web browser. An example is shown
            in the image of the Bob's Wine Bar site. The overall interace layout
            is:
            <ul>
              <li>
                An expandable <strong>navigation bar</strong> on the left. This
                is your top-level menu.
              </li>
              <li>
                A <strong>title bar</strong> on the top with access to several
                sitewide feature controls.
              </li>
              <li>
                The <strong>main body</strong> uses the remaining space &mdash;
                this where you'll interact with most of the programs and tools
                on the site.
              </li>
            </ul>
            Many websites use this layout so it should be familiar.
          </Paragraph>
          <Paragraph>
            The <strong>navigation bar</strong> is the main navigational menu
            for the site: it's how you "get around" to the site's various
            features. There are several default items, some that are results
            from your translation, and some that are part of your developer's
            customizations. The Wine Bar example has several menu items on it:
            <ul>
              <li>
                <strong>Home</strong> gets you back to the top-level view
              </li>
              <li>
                <strong>Workflows</strong> expands to reveal several menu items
                for pages that are part of our custom workflow management
                toolset. Your solution may not include this item since it is a
                separate product.
              </li>
              <li>
                <strong>TWINOAK</strong> expands to reveal the "WINE" item. This
                item launches the "WINE" menu that was translated from our AS400
                sample code. TWINOAK is the name of AS400 library that was
                translated to produce the site. You may have several items in
                place of this one if several libraries were translated.
              </li>
              <li>
                <strong>Reports</strong> displays the Report Browser when you
                can see all of your generated reports. You can launch the Report
                Viewer to see the report contents from inside the Report
                Browser.
              </li>
              <li>
                <strong>Explorer</strong> displays a file explorer that lets you
                view the list of files (reports and data files) stored in your
                system.
              </li>
              <li>
                <strong>Guestbook</strong> launches a custom application that we
                added to the translated site to demonstrate extensibility. You
                may have several items or none in place of this one depending on
                whether / if you have customized your solution.
              </li>
              <li>
                <strong>Help</strong> displays some explanatory text on how to
                use the interface.
              </li>
            </ul>
          </Paragraph>
        </div>
        <Paragraph>
          The <strong>title bar</strong> is used for branding your solution,
          giving you context about the menu and/or program selections you have
          made, providing you access to some top-level features of the site. The
          small icons on the right side of the title bar give you access to
          these top-level features, which include User Settings, Log In/Out,
          User Feedback, and Notifications.
        </Paragraph>
        <Paragraph>
          The <strong>main body</strong> area is the most dynamic since it's the
          area that your programs use for their input and output. Translated
          menus and forms display and accept inputs here. The Report Browser
          display the available reports here.
        </Paragraph>
        <Heading2>Before and After Views</Heading2>
        <Paragraph>
          This section shows how the visual experience changes when you migrate
          to your translated solution. The menus, reports, screens, DFUs and
          reports that you're used to are still available, but their appearance
          has changed. You can see these changes and learn more about them in
          the following paragraphs.
        </Paragraph>
        <Heading3>Menus</Heading3>
        <Paragraph>
          Our menu translation converts the menus defined on your AS400 into
          equivalent menus hosted by our SPA. The menus retain their ordering,
          structure, and descriptive text. You can use the settings features in
          our SPA to choose between a modern look (as shown), a list of
          launching buttons, or a green-screen emulation page that looks very
          much like your old screens.
        </Paragraph>
        <Paragraph>
          The before and after view below shows the "WINE" menu from the Bob's
          Wine Bar site. Note that menu item numbering is that same, but the
          empty menu items have been collapsed to save screen real estate.
        </Paragraph>
        <Paragraph>
          Click{" "}
          <a href="https://demo.twinoaksolutions.com/menus/twinoak/wine">
            here <img src={wineImage} className="icon" />{" "}
          </a>
          to see the live, translated version of this menu.
        </Paragraph>
        <BeforeAndAfterTwinOak
          beforeSrc={screenshotMenuBeforeImage}
          beforeCaption="The appearance of the original menu."
          afterSrc={screenshotMenuAfterImage}
          afterCaption="The appearance of the translated menu."
        />
        <Heading3>Screens</Heading3>
        <Paragraph>
          Our screen translation converts the screens defined on your AS400 into
          equivalent forms hosted by our SPA. The Bob's Wine Bar
          before-and-after images show very similar views. The new forms retain
          their original labels and field entry areas, with the same ordering,
          layout, and data types as the originals.
        </Paragraph>
        <Paragraph>
          Click{" "}
          <a href="https://demo.twinoaksolutions.com/procedures/twinoak/wn003?endOfJobUrl=/menus/twinoak/wine">
            here <img src={wineImage} className="icon" />
          </a>{" "}
          to see the live, translated version of this screen.
        </Paragraph>
        <BeforeAndAfterTwinOak
          beforeSrc={screenshotScreenBeforeImage}
          beforeCaption="The appearance of the original form."
          afterSrc={screenshotScreenAfterImage}
          afterCaption="The appearance of the translated form."
        />
        <Paragraph>
          As an alternative to the default translation, your developers can
          create custom modern interfaces using React, our SPA support library,
          and our backend APIs.
        </Paragraph>
        <Heading3>DFUs</Heading3>
        <Paragraph>
          Our Data File Update (DFU) translation converts your AS400 DFUs into
          equivalent forms hosted by our SPA. The DFUs retain their original
          labels and field entry areas, with the same record selections, field
          selections and data types as the originals. It supports all of the
          same field lables and data types you are used to including support for
          packed fields.
        </Paragraph>
        <Paragraph>
          Click{" "}
          <a href="https://demo.twinoaksolutions.com/data-files/twinoak/wine/WINES">
            here <img src={wineImage} className="icon" />
          </a>{" "}
          to see the live, translated version of this DFU.
        </Paragraph>
        <BeforeAndAfterTwinOak
          beforeSrc={screenshotDfuBeforeImage}
          beforeCaption="The appearance of the original DFU."
          afterSrc={screenshotDfuAfterImage}
          afterCaption="The appearance of the translated DFU."
        />
        <Heading3>Reports</Heading3>
        <Paragraph>
          Many existing programs and procedures are designed to generate report
          output. We currently don't support report translation (although that
          may change in the future) but the translated programs and procedures
          that generate reports generate them exactly as they are on your
          original system. If you need new versions of your old reports, you can
          run the programs in the new system to get them as long as your data
          has been translated.
        </Paragraph>
        <BeforeAndAfterTwinOak
          beforeSrc={screenshotReportBeforeImage}
          beforeCaption="The appearance of an original report."
          afterSrc={screenshotReportAfterImage}
          afterCaption="The appearance of the same report as generated by the new system."
        />
        <Paragraph>
          All reports that get generated will show up in the report organizer
          section of the SPA. This organizer is similar to the spool file list
          in your AS400 and also like the file explorer that appears on a
          windows system. This organizer displays information about all of the
          reports and allows you to filter and sort them to better find the
          report(s) you need.
        </Paragraph>
        <Paragraph>
          Click{" "}
          <a href="https://demo.twinoaksolutions.com/reports">
            here <img src={wineImage} className="icon" />
          </a>{" "}
          to see the live version of the report organizer.
        </Paragraph>
        <BeforeAndAfterTwinOak
          beforeSrc={screenshotReportListBeforeImage}
          beforeCaption="The appearance of the spool file list on the original AS400."
          afterSrc={screenshotReportListAfterImage}
          afterCaption="The appearance of the report list in the report organizer."
        />
      </Section>
    </>
  );
};

export default UserTour;
